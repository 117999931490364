import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import { setting } from '@/utils'
import { initAMapApiLoader } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
import Print from '@/utils/print'

import './nycClient.js';

import '@/assets/element/index.scss'

initAMapApiLoader({
  key: setting.amapKey,
  securityJsCode: setting.amapJsCode,
  plugins: ['AMap.Geocoder', 'AMap.Marker']
})

const app = createApp(App)

app.config.errorHandler = (err, instance, info) => {
  console.log(err, instance, info);
}

// 自定义指令
// 设置el-table的height,在父标签加上v-height
app.directive('height', (el, binding) => {
  // binding.value = margin的值,用户手动输入
  var form = el.querySelector('.td');
  var page = el.querySelector('.page');
  var h = 0;
  if(form) h += form.offsetHeight;
  if(page) h += page.offsetHeight;
  if(binding.value) h += binding.value;
  if(el.getElementsByClassName('table')[0]){
    el.getElementsByClassName('table')[0].setAttribute('style', `height: calc(100% - ${h}px)`)
  }
})

// webSocket
import socket from '@/utils/websocket.js'
// 连接webSocket
socket.global_websocket_connect();
// webSocket end

app.use(createPinia())
app.use(router)
app.use(Print)
app.mount('#app')
