import { createRouter, createWebHashHistory } from 'vue-router';
import { getItem } from '../assets/js/storage.js';
import routes from './model';
import { tool } from '@/stores/tool';
import mapMenu from '@/components/main/menu.js';

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

var list = [];
mapMenu.forEach(e => {
  list.push(e);
  if(e.children && e.children.length){
    list = [...list, ...e.children];
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  var token = getItem('token');
  let sideView = tool().sideView;
  let sideBar = tool().sideBar;
  if(token){
    if (to.path == '/login') {//token存在，并且是login页面
      next({name: 'home'});
    } else {//token存在，不是login页面
      if(sideView.findIndex(o => o == to.meta.permission) >= 0 || to.meta.permission == ''){
        next();
      }else{
        const route = sideBar[0];
        if(route && route.child && route.child.length){
          const r = route.child[0];
          const obj = list.find(o => o.permission == r.permission);
          next(obj.path);
        }else{
          let obj = list.find(o => o.permission == route.permission);
          next(obj.path);
        }
      }
    }
  }else{
    if (to.path == '/login') {//token不存在，并且是login页面
      next();
    } else {//token不存在，不是login页面
      next('/login');
    }
  }
})

export default router
